<template>
	<Layout>
		<Header :bordered="false" />
		<div class="dashboard">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
			<div v-if="rating.name" class="dashboard__title pt-3 mb-4">
				<div v-if="ratings.length > 1">
					<div class="dashboard__select">
						<select name="ratings" id="ratings" @change="updateDashboard">
							<option v-for="r in ratings" :key="r.id" class="is-capitalized" :value="r.id" :selected="rating.id == r.id">{{ r.name }}</option>
						</select>
						<svg-icon icon="down" class="icon is-small"></svg-icon>
					</div>
				</div>
				<h3 v-else class="dashboard__select">
					<span class="dashboard__select__inner">{{ rating.name }}</span>
				</h3>
				<h4 class="is-size-6 has-text-grey-light">{{ format(rating.start) }} até {{ format(rating.end) }}</h4>
				<a @click.prevent="downloadFilesPolicy()" class="button is-info is-outlined btn-policy">
						<svg-icon icon="download" class="icon is-small"></svg-icon>
					<span>Política de Bônus</span>
				</a>
			</div>
			<b-carousel-list v-if="overview.length > 0" animated="slide" :data="overview" :has-drag="true" :items-to-show="isDesktop ? 6 : isTablet ? 4 : 2">
				<template #item="overview">
					<div class="dashboard__box has-text-centered px-2 pt-3 pb-5" :class="slugify(overview.type)">
						<h4 class="dashboard__box__value">
							{{ overview.value }}
							<small v-if="overview.type == 'Bônus'">%</small>
						</h4>
						<span class="dashboard__box__type">{{ overview.type }}</span>
					</div>
				</template>
			</b-carousel-list>
			<Results v-else class="dashboard__error" title="" message="Não existe <strong>nenhuma</strong> avaliação <br>em progresso no momento!" />
			<div v-if="user">
				<section class="columns is-multiline is-mobile mt-1" v-if="user.role.name == 'user'">
					<DealersDashboard :dealers="dealers" :ratingId="rating.id" />
					<article class="column is-12-mobile is-two-third-desktop">
						<iframe v-if="currentPolicy && ['pdf', 'PDF'].includes(currentPolicy.slice(-3))" class="dashboard__image" :src="currentPolicy" width="100%" height="345" />
						<div v-else class="dashboard__image" :class="'dashboard__image--' + randomNumber(1, 5)"></div>
					</article>
				</section>
				<section class="columns is-multiline is-mobile mt-1" v-else>
					<Ranking :items="top(dealers)" />
					<Ranking :items="bottom(dealers)" order="bottom" />
					<Latest :items="latest(dealers).slice(0, 5)" />
				</section>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Ranking from '@/components/Ranking'
import Latest from '@/components/Latest'
import DealersDashboard from '@/components/DealersDashboard'
import Results from '@/components/Results'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { mapState } from 'vuex'
import '@/mixins/generic'

export default {
	name: 'Dashboard',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Results,
		Ranking,
		Latest,
		DealersDashboard,
		'svg-icon': Icon
	},
	data() {
		return {
			loading: true,
			bordered: true,
			ratings: [],
			rating: {},
			overview: [],
			dealers: [],
			currentPolicy: null
		}
	},
	methods: {
		randomNumber(min, max) {
			min = Math.ceil(min)
			max = Math.floor(max)

			return Math.floor(Math.random() * (max - min + 1)) + min
		},
		updateDashboard(e) {
			let id = e.target.value
			this.getDashboardData(id)
			console.log(e)
		},
		async getAllRatings() {
			try {
				const response = await Api.get('rating/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.ratings = data
					// this.loading = false
				} else {
					this.errored = true
				}
				this.getDashboardData('')
			} catch (e) {
				this.errored = true
				console.log(e)
			} finally {
				//this.loading = false
			}
		},
		async getDashboardData(id) {
			this.loading = true
			try {
				const response = await Api.get(`dashboard${id ? `/${id}` : ''}`)
				const { status, data } = response

				if (status === 200) {
					let r = data.data[0]
					this.loading = false
					this.rating = r.rating
					this.overview = r.overview
					this.dealers = r.dealers
					// console.log('getDashboardData', r)
					this.setPolicyUrl()
				}
			} catch (e) {
				this.loading = false
				console.log(e)
			}
		},
		bottom(arr) {
			return arr.slice().sort(function(a, b) {
				return a.rate - b.rate
			})
		},
		top(arr) {
			return arr.slice().sort(function(a, b) {
				return b.rate - a.rate
			})
		},
		latest(arr) {
			return arr.slice().sort(function(a, b) {
				return new Date(b.date) - new Date(a.date)
			})
		},
		setPolicyUrl() {
			const currentRate = this.ratings.find(r => r.id == this.rating.id)

			currentRate.files.forEach((f, i) => {
				if (i == 0) {
					let urlApi = process.env.VUE_APP_BASE_API
					let urlFile = `${urlApi.replace('/api/', '/storage/')}${f}`
					this.currentPolicy = urlFile
				}
			})
		},
		downloadFilesPolicy() {
			const currentRate = this.ratings.find(r => r.id == this.rating.id)

			currentRate.files.forEach((f) => {
				this.downloadFile(f)
			})
		},
		downloadFile(file) {
			Api.post(`rating/download`, { file: file }, { responseType: 'blob' }).then(response => {
				let blob = new Blob([response.data], { type: response.data.type })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				let filename = file.split('/')
				link.download = filename[filename.length - 1]
				link.click()
			})
		}
	},
	mounted() {
		this.getAllRatings()
	},
	computed: {
		...mapState('user', ['user'])
	}
}
</script>
